import React, { useState } from "react"
import tw from "tailwind-styled-components"
import styled from "styled-components"
import { BiRightArrowAlt } from "react-icons/bi"

const ArrowButton = props => {
  const [buttonHover, setButtonHover] = useState(false)

  const buttonOnHoverHandler = () => {
    setButtonHover(true)
  }

  const buttonOLeaveHandler = () => {
    setButtonHover(false)
  }

  return (
    <div className="relative inline-block w-auto">
      <Arrow $isHover={buttonHover} $colorVar={props.colorVar}>
        <TwBiRightArrowAlt $isHover={buttonHover} $colorVar={props.colorVar} />
      </Arrow>
      <Button
        onClick={props.buttonClick}
        $isHover={buttonHover}
        onMouseEnter={buttonOnHoverHandler}
        onMouseLeave={buttonOLeaveHandler}
        $colorVar={props.colorVar}
        $buttonStyle={props.buttonStyle}
        className={props.buttonClass}
      >
        {props.children}
      </Button>
    </div>
  )
}

ArrowButton.defaultProps = {
  colorVar: "red",
  buttonStyle: "expanded",
}

const ButtonStyled = styled.button`
  white-space: nowrap;
  ${e => {
    switch (e.$buttonStyle) {
      case "expanded":
        return "min-width: 150px"
      case "tight":
      default:
        break
    }
  }}
`

const Button = tw(ButtonStyled)`
    ${e => {
      switch (e.$colorVar) {
        case "orange":
          return "bg-bkpm-orange"
        case "green":
          return "bg-bkpm-green"
        case "red":
        default:
          return "bg-bkpm-red"
      }
    }}
    ${e => {
      switch (e.$buttonStyle) {
        case "expanded":
          return "px-12"
        case "tight":
        default:
          return "pl-4 pr-10"
      }
    }}
    uppercase
    transition-all
    duration-300
    py-3
    rounded-md
    leading-none
    tracking-wide
    font-semibold
    text-white
`

const Arrow = tw.span`
    absolute
    top-1/2
    transform
    -translate-y-1/2
    transition-all
    duration-300
    w-6
    h-6
    flex
    justify-center
    items-center
    rounded-full
    border-2
    border-solid
    pointer-events-none
    text-white
    ${e => {
      switch (e.$colorVar) {
        case "orange":
          return "border-bkpm-orange"
        case "green":
          return "border-bkpm-green"
        case "red":
        default:
          return "border-bkpm-red"
      }
    }}
    ${e => (e.$isHover ? "-right-3 bg-white" : "right-2 bg-transparent")}
`

const TwBiRightArrowAlt = tw(BiRightArrowAlt)`
    ${e => {
      if (e.$isHover) {
        switch (e.$colorVar) {
          case "orange":
            return "text-bkpm-orange"
          case "green":
            return "text-bkpm-green"
          case "red":
          default:
            return "text-bkpm-red"
        }
      }
    }}
`

export default ArrowButton
