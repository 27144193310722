import React, { useState } from "react"

const getSesiLanguage =
  typeof window !== "undefined" ? localStorage.getItem("language") : null
const LanguageContext = React.createContext({
  language: getSesiLanguage || "id",
  toggleLanguage: () => {},
})

export const LanguageContextProvider = props => {
  const [language, setLanguage] = useState(getSesiLanguage || "id")
  const toggleLanguage = (langId = null) => {
    if (langId !== null) {
      localStorage.setItem("language", langId)
      // setLanguage(langId)
      if (window !== "undefined") window.location.reload()
    } else {
      const newLanguage = language
      setLanguage(newLanguage)
    }
  }

  return (
    <LanguageContext.Provider
      value={{ language: language, toggleLanguage: toggleLanguage }}
    >
      {props.children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext
