import React from 'react'

import tw from 'tailwind-styled-components'

const Container = tw.div`
    mx-auto
    container
    px-6
`
const ContainerFluid = tw.div`
    w-full
    px-6
`

const ResponsiveContainer = (props) => <Container {...props}>{ props.children }</Container>

const ResponsiveContainerFluid = (props) => <ContainerFluid {...props}>{ props.children }</ContainerFluid>

export default ResponsiveContainer

export { ResponsiveContainerFluid }